import React, { useState } from "react";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import { Button } from "../Styles/Buttons";
import styled from "styled-components";
import countryList from "./countries";
import Textarea from "./Textarea";
import { H4 } from "../Styles/Typography";
import { ButtonIcon } from "../Icons/Icons";
import Dropzone from "react-dropzone";
import { FormLabel } from "../Styles/Form";

const FundingForm = () => {
  const defaultValues = {
    honeypot: "",
    firstName: "",
    lastName: "",
    phoneCode: "",
    phoneNumber: "",
    email: "",
    country: "select",
    companyName: "",
    companyRole: "",
    linkedIn: "",
    bio: "",
    projectInitialization: "",
    companyRegistration: "",
    involvesBlockchain: "",
    developmentStage: "",
    problemToSolve: "",
    MarketValue: "",
    competitors: "",
    blockchainNecessity: "",
    projectAttributes: "",
    capitalRaising: "",
    whitepaperURL: "",
    websiteURL: "",
    tokensAssociated: "",
    tokenPlatform: "",
    tokenNumber: "",
    tokenCreationStrategy: "",
    tokenBurnable: "",
    tokenAllocation: "",
    tokenVestingPeriod: "",
    fundraisingGoals: "",
    raised: "",
    otherDetails: "",
    files: [],
    filesURL: ""
  };

  const [values, setValues] = useState(defaultValues);
  const [isFormSuccess, setFormState] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);
  const [areFilesValid, setFilesValidity] = useState(true);

  const handleOnChange = e => {
    const name = e.target.name;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setValues({
      ...values,
      [name]: value
    });

    if (name === "country") {
      let countrySelected = countryList.find(
        country => country.name === value
      );

      setValues({
        ...values,
        country: countrySelected.name,
        phoneCode:
          countrySelected !== undefined ? countrySelected.phone_code : ""
      });
    }
  };

  const onDrop = files => {
    if (files.length < 1) return;
    setFilesValidity(true);

    let filePromises = files.map(file => {
      if (file === null || file === undefined) return;

      // check if file size is greater than 5mb
      if (file.size > (4 * 1024 * 1024)) {
        setFilesValidity(false);
        return;
      }

      // check file types
      if (
        file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
        file.type !== "application/msword" &&
        file.type !== "text/csv" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "application/pdf" &&
        file.type !== "application/vnd.ms-powerpoint" &&
        file.type !== "application/vnd.openxmlformats-officedocument.presentationml.presentation" &&
        file.type !== "image/svg+xml" &&
        file.type !== "text/plain" &&
        file.type !== "application/vnd.ms-excel" &&
        file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        file.type !== "application/x-rar-compressed" &&
        file.type !== "application/zip" &&
        file.type !== "application/x-7z-compressed"
      ) {
        setFilesValidity(false);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve({
            data: reader.result.split("base64,")[1],
            name: file.name.replace(/\s/g, "_"),
            type: file.type
          });
        };
      });
    });

    Promise.all(filePromises).then(res => {
      const files = res.filter((r) => r !== undefined);
      setValues({
        ...values,
        files
      });
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    if (values.honeypot !== "") return;

    const emailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{1,15})$/;
    if (!emailRegex.test(values.email)) return;
    if (values.companyName === "") return;
    if (values.firstName === "") return;
    if (values.lastName === "") return;
    if (values.phoneNumber === "") return;
    if (values.country === "") return;

    setFormLoading(true);

    fetch(
      `https://mrww0qh2ub.execute-api.us-east-1.amazonaws.com/dev/finova-capital-funding-form`,
      {
        method: "POST",
        body: JSON.stringify({
          ses_address: "contact@finova-capital.com",
          // TODO:
          send_to: "contact@finova-capital.com",
          subject: "Finova Capital | Funding Application",
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneCode + values.phoneNumber,
          country: values.country,
          companyName: values.companyName.replace(/\s/g, "_"),
          companyRole: values.companyRole,
          bio: values.bio,
          linkedIn: values.linkedIn,
          projectInitialization: values.projectInitialization,
          companyRegistration: values.companyRegistration,
          involvesBlockchain: values.involvesBlockchain,
          developmentStage: values.developmentStage,
          problemToSolve: values.problemToSolve,
          MarketValue: values.MarketValue,
          competitors: values.competitors,
          blockchainNecessity: values.blockchainNecessity,
          projectAttributes: values.projectAttributes,
          capitalRaising: values.capitalRaising,
          whitepaperURL: values.whitepaperURL,
          websiteURL: values.websiteURL,
          tokensAssociated: values.tokensAssociated,
          tokenPlatform: values.tokenPlatform,
          tokenNumber: values.tokenNumber,
          tokenCreationStrategy: values.tokenCreationStrategy,
          tokenBurnable: values.tokenBurnable,
          tokenAllocation: values.tokenAllocation,
          tokenVestingPeriod: values.tokenVestingPeriod,
          fundraisingGoals: values.fundraisingGoals,
          raised: values.raised,
          otherDetails: values.otherDetails,
          files: values.files,
          filesURL: values.filesURL
        }),
        headers: new Headers({
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8"
        })
      }
    )
      .then(() => {
        setFormState(true);
        setFormLoading(false);
        setValues(defaultValues);
        setTimeout(() => {
          setFormState(false)
        }, 2000);
      })
      .catch(err => {
        setFormState(false);
        setFormLoading(false);
        console.log(err);
      });
  };

  return (
    <FormWrapper className="container">
      <form onSubmit={ onSubmit }>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <input type="input" name="honeypot" readOnly={ true } value="" style={ { display: "none" } } tabIndex="-1"
                   autoComplete="off" />
            <FormInput required label="First Name" type="text" name="firstName" value={ values.firstName }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput required label="Last Name" type="text" name="lastName" value={ values.lastName }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormSelect required label="Country of Residency" name="country" value={ values.country }
                        options={ countryList } onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput required label="Phone Number" type="tel" name="phoneNumber" prefix={ values.phoneCode }
                       value={ values.phoneNumber } onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput required label="Company Name" type="text" name="companyName" value={ values.companyName }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput label="Company Role" type="text" name="companyRole" value={ values.companyRole }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput required label="Email" type="email" name="email" value={ values.email }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput label="LinkedIn URL" type="text" name="linkedIn" value={ values.linkedIn }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12">
            <Textarea label="Bio" name="bio" value={ values.bio } onChange={ handleOnChange } />
          </div>
        </div>
        <H4>Project Details</H4>
        <div className="row">
          <CompressedTextarea className="col-xs-12 col-md-6">
            <Textarea label="When was your project initiated by the founders?" name="projectInitialization"
                      value={ values.projectInitialization } onChange={ handleOnChange } />
          </CompressedTextarea>
          <CompressedTextarea className="col-xs-12 col-md-6">
            <Textarea label="Where is your company registered?" name="companyRegistration"
                      value={ values.companyRegistration } onChange={ handleOnChange } />
          </CompressedTextarea>
          <div className="col-xs-12">
            <Textarea
              label="Besides the initial offering, how does your project involve blockchain or other distributed ledger technology (directly or indirectly)?"
              name="involvesBlockchain" value={ values.involvesBlockchain } onChange={ handleOnChange } />
            <Textarea label="Do you have a working product? What is the development stage?" name="developmentStage"
                      value={ values.developmentStage } onChange={ handleOnChange } />
            <Textarea label="What problem does your project/product aim to solve?" name="problemToSolve"
                      value={ values.problemToSolve } onChange={ handleOnChange } />
            <Textarea
              label="What is the estimated value of the market that your project aims to enter (and the expected market share)?"
              name="MarketValue" value={ values.MarketValue } onChange={ handleOnChange } />
            <Textarea label="Who are your primary competitors?" name="competitors" value={ values.competitors }
                      onChange={ handleOnChange } />
            <Textarea
              label="Why does blockchain or other distributed ledger technology is necessary to tackle the problem that your product aims to solve?"
              name="blockchainNecessity" value={ values.blockchainNecessity } onChange={ handleOnChange } />
            <Textarea
              label="What attributes does your project primarily rely on to solve in the blockchain technology space?"
              name="projectAttributes" value={ values.projectAttributes } onChange={ handleOnChange } />
            <Textarea label="How do you plan to raise capital? Are there any legal restrictions on the offering?"
                      name="capitalRaising" value={ values.capitalRaising } onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput label="Link to Whitepaper" type="text" name="whitepaperURL" value={ values.whitepaperURL }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput label="Link to Website" type="text" name="websiteURL" value={ values.websiteURL }
                       onChange={ handleOnChange } />
          </div>
        </div>
        <H4>Token Metrics</H4>
        <div className="row">
          <div className="col-xs-12">
            <Textarea
              label="How many distinct tokens/coins are associated with your project? What kind of tokens/coins are they?"
              name="tokensAssociated" value={ values.tokensAssociated } onChange={ handleOnChange } />
            <Textarea label="What platform will your tokens be issued on? Eg. Ethereum, NEO, native blockchain?"
                      name="tokenPlatform" value={ values.tokenPlatform } onChange={ handleOnChange } />
            <Textarea label="What is the total number of tokens to be issued in the offering and in total?"
                      name="tokenNumber" value={ values.tokenNumber } onChange={ handleOnChange } />
            <Textarea label="What is the token creation strategy/mechanism? Eg. mined, pre-mined?"
                      name="tokenCreationStrategy" value={ values.tokenCreationStrategy } onChange={ handleOnChange } />
            <Textarea label="Are your tokens burnable? In which scenarios are they burned?" name="tokenBurnable"
                      value={ values.tokenBurnable } onChange={ handleOnChange } />
            <Textarea
              label="What's the token allocation? Eg. Team & advisors, operational expenses, private sale, ICO, mining etc."
              name="tokenAllocation" value={ values.tokenAllocation } onChange={ handleOnChange } />
            <Textarea label="Is there a vesting period for team tokens (lockup)?" name="tokenVestingPeriod"
                      value={ values.tokenVestingPeriod } onChange={ handleOnChange } />
            <Textarea
              label="What are your fundraising goals for each round and what is your timeline for this process (Private-sale, Pre-sale, Public Offering etc.)"
              name="fundraisingGoals" value={ values.fundraisingGoals } onChange={ handleOnChange } />
            <Textarea
              label="How much have you raised to date? Please provide details regarding each round (vesting, bonusses, caps etc.)"
              name="raised" value={ values.raised } onChange={ handleOnChange } />
            <Textarea label="Other details?" name="otherDetails" value={ values.otherDetails }
                      onChange={ handleOnChange } />
          </div>
        </div>
        <H4>File upload or shared link</H4>
        <div className="row">
          <div className="col-xs-12">
            <FormInput label="Shared folder or files URL" type="text" name="filesURL" value={ values.filesURL }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12">
            <DropzoneLabel>
              Upload your file (Max. file size: 5mb)
            </DropzoneLabel>
            <Dropzone onDrop={ onDrop }>
              { ({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <DropZoneContainer
                    { ...getRootProps() } className={ `dropzone ${
                    isDragActive ? "dropzone--isActive" : ""
                    } ${ values.files.length > 0 ? "dropzone--isUploaded" : "" }` }>
                    <input { ...getInputProps() } />
                    {
                      values.files.length > 0 ?
                        <p>{ values.files.length } files uploaded</p>
                        : isDragActive ? (
                          <p>Drop files here...</p>
                        ) : (
                          <p>
                            Try dropping some files here, or click to select files
                            to upload. </p>
                        )
                    }
                  </DropZoneContainer>
                );
              } }
            </Dropzone>
            <FilesMessage className="col-xs-12">
              {
                !areFilesValid && <FilesAlert>File(s) too big or invalid. Maximum file size is 5mb.</FilesAlert>
              }
              {
                values.files.length > 0 && <FilesItems>
                  {
                    values.files.map((file, index) => <FilesItem key={ index }>{ file && file.name }</FilesItem>)
                  }
                  </FilesItems>
              }
            </FilesMessage>
          </div>
        </div>
        <SendSection className="row">
          <div className="col-xs-12">
            <p>
              You can also contact us at: <a>contact@Finova-Capital.com</a>
            </p>
          </div>
          <div className="col-xs-12">
            <Button as="button" type="submit">
              { !isFormLoading &&
              !isFormSuccess && <span>Send application</span> }
              { isFormLoading && (
                <>
                  Sending <ButtonIcon name="loading" />
                </>
              ) }
              { isFormSuccess &&
              !isFormLoading && (
                <>
                  Successfully sent <ButtonIcon name="check" />
                </>
              ) }
            </Button>
          </div>
        </SendSection>
      </form>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  max-width: 860px;
  margin: 0 auto 2rem;
`;

const CompressedTextarea = styled.div`
  label {
    min-height: 38px;
  }
`;

const SendSection = styled.div`
  text-align: center;

  p {
    margin-bottom: 2rem;
  }
`;

const FilesMessage = styled.div`
  margin: 1rem auto;
`;

const FilesItems = styled.div`
  max-height: 250px;
  overflow: auto;
`;

const FilesItem = styled.span`
  display: block;
  font-size: 14px;
`;

const FilesAlert = styled.span`
  color: red;
  font-weight: bold;
`;

const FilesQuantity = styled.span`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const DropZoneContainer = styled.div`
  width: 100%;
  border: 1px dashed ${ props => props.theme.mainLight3 };
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  
  &.dropzone--isActive,
  &.dropzone--isUploaded {
    border-color: ${ props => props.theme.mainAccent };
  }
`;

const DropzoneLabel = styled(FormLabel)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export default FundingForm;
