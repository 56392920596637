import React from "react";
import MainLayout from "../components/Layout/MainLayout";
import FundingForm from "../components/Form/FundingForm";
import fallbackImage from "../../static/fallback-image.jpg";
import styled from "styled-components";
import { H1 } from "../components/Styles/Typography";
import { BackgroundImage, FallbackBackgroundImage } from "../components/Styles/BackgroundImage";
import { graphql } from "gatsby";

const FundingFormPage = React.memo(({ data, location }) => {
  const {
    content,
    featured_media,
    title,
  } = data.allWordpressPage.edges[0].node;

  return (
    <MainLayout location={location}>
      <Img className="container-fluid">
        { featured_media && featured_media.localFile ? (
          <BackgroundImage sizes={ featured_media.localFile.childImageSharp.fluid } />
        ) :
          <FallbackBackgroundImage style={ { backgroundImage: `url(${ fallbackImage })` } } />
        }
        <IntroBody>
          <H1>{title}</H1>
        </IntroBody>
      </Img>
      <Paragraph className="container">
        <div className="row">
          <div
            className="col-xs-12 col-md-8 col-md-offset-2"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </Paragraph>
      <FundingForm />
    </MainLayout>
  );
});

const Img = styled.div`
  height: 50vh;
  width: 100%;
  top: -100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IntroBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  padding-top: 3rem;
`;

const Paragraph = styled.div`
  margin-bottom: 5.5rem;
  text-align: center;

  strong {
    font-weight: bold;
  }
`;

export const FundingQuery = graphql`
  query FundingQuery {
    allWordpressPage(filter: { slug: { regex: "/apply-for-funding/" } }) {
      edges {
        node {
          slug
          title
          content
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FundingFormPage;
